import './App.css';
import Header from './component/header/Header';
import Carrousel from './component/carrousel/carrousel';

function App() {
  return (
    <div className="App">
      <div class="header">
      <Header></Header>
      <Carrousel></Carrousel>
      </div>
    </div>
  );
}

export default App;
