
import './Header.css';
import React from 'react';
import lavande from './DSC04255.jpg';

const Header = () => {
  const items = [
    { label: "Quetzal'IT", icon: 'pi pi-fw pi-home'},
    { label: 'Voyages', icon: 'pi pi-fw pi-blog'},
  ];
  const tabs = []

  items.forEach((item) => {
    tabs.push(<div className='menu-label'>{item.label}</div>)
  })

  return (
    <div>
      <img class="lavande" src={lavande} alt="lavande" />
      <div className=" menu-lavande">
        {tabs}
      </div>
    </div>
  );
}

export default Header;